import styles from './Navbar.module.scss';
import { useMediaQuery } from 'react-responsive';

const Navbar = () => {

  // Collapsible media query
  const isGreaterThan768 = useMediaQuery({ query: '(min-width: 768px)' })

  // Used to override classNames for collapsible hamburger icon
  const s = {
    navbar: {
      position: "fixed",
      width: "100%",
      backgroundColor: "black",
      zIndex: 999
    },
    hamburgerIcon: {
      backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")`
    },
    border: {
      border: "none",
      // borderColor: "red"
    },
    collapseNavBarColor: {
      backgroundColor: isGreaterThan768 ? "black" : "rgba(255, 255, 255, 0.15)" //"rgba(47, 174, 233, 0.8)"
    },
  }

  return (
    <div style={s.navbar}>
      <div style={{maxWidth:"1000px",margin:"auto"}}>
        <nav className="navbar navbar-expand-md navbar-light" style={{padding:"0px"}}>
          <div className="container-fluid" style={{padding:"0px"}}>
            <a className="navbar-brand" href="www.google.com" style={{color:"rgb(47, 174, 233)",fontWeight:"bold",fontFamily:"bradley-hand",letterSpacing:"2px",marginLeft:"15px"}}>Jake Keenan</a>
            <button className="navbar-toggler" style={s.border} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" style={s.hamburgerIcon}></span>
            </button>
            
            <div className="collapse navbar-collapse" style={s.collapseNavBarColor} id="navbarSupportedContent">
              <div style={{backgroundColor:"rgba(47, 174, 233, 1)",height:"3px"}}></div>
              <ul className="navbar-nav ms-auto" style={{letterSpacing:"1px",fontFamily:"TimesNewRoman"}}>
                <li className="nav-item" style={{padding:"0px 20px 0px 20px"}}>
                  <a className="nav-link active" href="#" style={{color:"white"}}>Home</a>
                </li>
                <li className="nav-item" style={{padding:"0px 20px 0px 20px"}}>
                  <a className="nav-link" 
                     href="#startOfAboutPage" 
                     style={{color:"white"}}>About</a>
                </li>
                <li className="nav-item" style={{padding:"0px 20px 0px 20px"}}>
                  <a className="nav-link" href="#startOfPortfolioPage" style={{color:"white"}}>Portfolio</a>
                </li>
                <li className="nav-item" style={{padding:"0px 20px 0px 20px"}}>
                  <a className="nav-link" href="#startOfContactPage" style={{color:"white"}}>Contact Me</a>
                </li>
              </ul>
            </div>
      
          </div>
        </nav>
        {/* <div style={{backgroundColor:"rgba(47, 174, 233, 1)",height:"3px",position:"sticky"}}></div> */}

      </div>

      <div style={{backgroundColor:"rgba(47, 174, 233, 1)",height:"3px"}}></div>

    </div>
  );
}



export default Navbar;