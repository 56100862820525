import styles from './About.module.scss';
import imageOfMyself from '../assets/image/graduationPhoto.webp';
import piIcon from '../assets/svg/pi_icon_symbol.svg';
import programmerIcon from '../assets/svg/programmer_icon_symbol.svg';
import dataScienceIcon from '../assets/svg/dataScience_icon.svg';
import webDeveloperIcon from '../assets/svg/webDeveloper_icon.svg';
const {REACT_APP_GITHUB_PROJECTS} = process.env

const About = () => {
  return (
    <div id="startOfAboutPage" style={{maxWidth:"1150px",margin:"auto"}}>
      <div style={{padding:"50px 0px 30px 0px",textAlign:"center",fontSize:"40px",color:"rgba(25, 181, 254, 0.7)"}}><u className={styles.underline}>About</u></div>
      <div style={{padding:"20px 30px 0px 30px"}}>
        <div style={{marginBottom:30}}>
          <FourSquareIcons />
        </div>

        <div style={{marginBottom:60}}>
          <PhotoSkills />
        </div>
      </div>
    </div>
  );
}

// Copied

const FourSquareIcons = () => {
  return (
    <div className={styles.a_row}>
      <OneSquareIcon
        image={programmerIcon}
        heading="Programmer"
        text="Proficient in multiple programming languages."
      />
      <OneSquareIcon
        image={dataScienceIcon}
        heading="Data Scientist"
        text="Graduated with a Master of Data Science at UNSW."
      />
      <OneSquareIcon
        image={webDeveloperIcon}
        heading="Web Developer"
        text="Made this website using React, SASS, GraphQL and AWS."
      />
      <OneSquareIcon
        image={piIcon}
        heading="Mathematician"
        text="Degree in Science (Maths and Stats) and Commerce (Finance)."
      />
    </div>
  );
}

const OneSquareIcon = ({image, heading, text}) => {
  return(
    <div className={styles.a_column}>
      {/* <div className="a_square">{image}</div> */}
      <div className={styles.a_square}><img src={image} href="" style={{width:"80px",height:"100px"}} alt="Image can't be displayed." /></div>
      <div className={styles.a_OSIfontsize}><b style={{color:"rgba(0,0,0,0.7)"}}>{heading}</b></div>
      <div className={styles.a_OSItext}><div style={{maxWidth:450,margin:"auto"}}>{text}</div></div>
    </div>
  );
}



const PhotoSkills = () => {
  return(
    <div className={styles.a_row}>
      <div className={styles.a_column2} style={{padding:10}}>
        <img src={imageOfMyself} className={styles.a_imageNumber111} alt="Image can't be displayed." />
        <div style={{fontSize:25,marginTop:10}}><b>Who am I?</b></div>
        <div style={{marginTop:5,fontFamily:"arial",fontSize:15}}>I'm a <span style={{color:"rgba(25, 181, 254, 0.9)"}}>data scientist</span> with a <i>generalist view</i>.</div>
        <div style={{marginTop:1,fontFamily:"arial",fontSize:15}}>I enjoy analysing messy datasets, taking <b>something from nothing</b>, seeing the real impact it has on business.</div>
        <a href={REACT_APP_GITHUB_PROJECTS} style={{marginTop:1,fontFamily:"arial",fontSize:15,color:"rgba(25, 181, 254, 0.9)"}}>See my work.</a>
      </div>
      <div className={`${styles.a_column2} ${styles.a_marginTopSpacing}`}>

        <Skill 
          nameOfSkill="Python"
          percentage="90%"
          // optionalMargin="10px"
        />
        <Skill 
          nameOfSkill="R"
          percentage="80%"
          optionalMargin="10px"
        />
        <Skill 
          nameOfSkill="SQL"
          percentage="70%"
          optionalMargin="10px"
        />
        <Skill 
          nameOfSkill="HTML"
          percentage="90%"
          optionalMargin="10px"
        />
        <Skill 
          nameOfSkill="CSS"
          percentage="85%"
          optionalMargin="10px"
        />
        <Skill 
          nameOfSkill="JavaScript"
          percentage="80%"
          optionalMargin="10px"
        />
        <Skill 
          nameOfSkill="React"
          percentage="85%"
          optionalMargin="10px"
        />
        <Skill 
          nameOfSkill="Node"
          percentage="70%"
          optionalMargin="10px"
        />
        <Skill 
          nameOfSkill="Express"
          percentage="75%"
          optionalMargin="10px"
        />
        <Skill 
          nameOfSkill="AWS"
          percentage="70%"
          optionalMargin="10px"
        />

      </div>
    </div>
  );
}


const Skill = ({optionalMargin, nameOfSkill, percentage}) => {
  return(
    <div className={styles.a_rowForSkills} style={{marginTop:optionalMargin}}>
      <div className={styles.a_skillName}>{nameOfSkill}</div>
      <div className={styles.a_skillBar}>
        <div style={{backgroundColor:"rgba(25,181,254,1)",width:percentage}}>
          <div className={styles.a_percentagesAlign}>{percentage}</div>
        </div>
      </div>
    </div>
  );
}

export default About;