import React, { useState } from 'react';
import './Contact.scss';
import Amplify, { API } from 'aws-amplify';
const { 
  REACT_APP_API_NAME, REACT_APP_API_PATH, REACT_APP_API_ENDPOINT, 
  REACT_APP_GITHUB_HOME, REACT_APP_LINKEDIN, REACT_APP_EMAIL } = process.env

// Configure existing API gateway connection 
// For endpoint just give the 
Amplify.configure({
  API: {
    endpoints: [
      {
        name: `${REACT_APP_API_NAME}`,
        endpoint: `${REACT_APP_API_ENDPOINT}`
      }
    ]
  }
})

const Contact = () => {
  return (
    <div id="startOfContactPage" style={{backgroundColor:"rgba(0, 0, 0, 0.9)"}}>
      <div style={{maxWidth:"1000px",margin:"auto"}}>
        <div style={{padding:"50px 0px 30px 0px",textAlign:"center",fontSize:"40px",color:"rgba(255, 255, 255, 1)"}}><u className="underline_c">Contact</u></div>
        <div style={{paddingBottom:"20px"}}>
          <ContactForm />
          <SquareIcons />
        </div>
      </div>
    </div>
  );
}


const SquareIcons = () => {
  return (
    <div className="c_column c_Margin c_mmm"> 
      <div style={{textAlign:"center",marginTop:20}}>
        <a className="socialMediaSquares" href={REACT_APP_GITHUB_HOME}>
          <span className="fa fa-github" style={{color:"white",fontSize:30,marginTop:10}}></span>
        </a>
        <a className="socialMediaSquares" href={REACT_APP_LINKEDIN}>
          <span className="fa fa-linkedin" style={{color:"white",fontSize:30,marginTop:10}}></span>
        </a>
        <a className="socialMediaSquares" href={`mailto: ${REACT_APP_EMAIL}`}>
          <span className="fa fa-envelope-o" style={{color:"white",fontSize:30,marginTop:10}}></span>
        </a>
      </div>
    </div>
  );
}


const ContactForm = () => {

  const initialFormValues = {
    name: "",
    email: "",
    message: ""
  }

  const [formData, setFormData] = useState(initialFormValues)

  const handleChange = (event) => {
    setFormData({...formData, [event.target.name]: event.target.value })
  }

  const regex = {
    namePatt: new RegExp(".+"),
    emailPatt: new RegExp(".+@.+"),
    messagePatt: new RegExp(".")
  }

  const [errorMessage, setErrorMessage] = useState("");
  const [wait, setWait] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault()
    if (regex.namePatt.test(formData.name) &&
        regex.emailPatt.test(formData.email) &&
        regex.messagePatt.test(formData.message)) {
          if (!wait) {
            setWait(true)
            createContact()
            setFormData(initialFormValues) // resets the 3 text fields
            setErrorMessage("Submitted Successfully.")
            setTimeout(() => {
              setErrorMessage("")
              setWait(false)
            }, 5000);
          }
    } else {
      if (!wait) {
        setWait(true)
        setErrorMessage("Please Try Again.")
        setTimeout(() => {
          setErrorMessage("")
          setWait(false)
        }, 5000);
      }
    }
  }

  const createContact = () => {
    const apiName = `${REACT_APP_API_NAME}`;
    const path = `${REACT_APP_API_PATH}`;
    const myInit = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData)
    }

    API
      .post(apiName, path, myInit)
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err.response)
      });
  }
  


  return (
    <div className="c_column">
      <div className="SCsubTitle">Have a question? Ask away!</div>

      <form className="c_mmm" style={{maxWidth:"450px",margin:"auto"}}>
        <div className="form-group">
          <input style={{backgroundColor:"black",border:"none",marginBottom:"-13px",color:"white"}} name="name" onChange={handleChange} value={formData.name} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Name" />
        </div>
        <div className="form-group">
          <input style={{backgroundColor:"black",border:"none",marginBottom:"-13px",color:"white"}} name="email" onChange={handleChange} value={formData.email} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" />
        </div>
        <div className="form-group">
          <textarea style={{backgroundColor:"black",border:"none",marginBottom:"-5px",color:"white"}} name="message" onChange={handleChange} value={formData.message} className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Message"></textarea>
        </div>
        <div className="row00">
          <div className="col11">
            {errorMessage === "Please Try Again." && <div style={{backgroundColor:"rgba(224,56,56,0.7)",textAlign:"center",padding:8}} className="fadeIT">{errorMessage}</div>}
            {errorMessage === "Submitted Successfully." && <div style={{backgroundColor:"rgba(67,212,67,0.7)",textAlign:"center",padding:8}} className="fadeIT">{errorMessage}</div>}
          </div>
          <div className="col22">
            <button onClick={handleSubmit} id="submit-but" type="submit" className="submitButton" style={{float:"right"}}>Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}


export default Contact;