import { useState} from "react";
import { useMediaQuery } from 'react-responsive'
import styles from './Portfolio.module.scss';
import appleDetectionImage from '../assets/image/greenRedApples.webp';
import aflBall from '../assets/image/aflBall.webp';
import BulldogsScarf from '../assets/image/bulldogsScarf.webp';
import PlantAndPlot from '../assets/image/customerChurn.webp';
// import placeholderImage from '../assets/image/placeholder.webp';
import timeSeries from '../assets/image/timeSeries.webp';
// import maskedTeddy from '../assets/image/maskedTeddy.webp';
const { 
  REACT_APP_APPLE_DETECTION, REACT_APP_AFL, REACT_APP_NRL,
  REACT_APP_APPLE_TIMESERIES, REACT_APP_TELCO_CHURN
} = process.env


const Portfolio = () => {
  return (
    <div id="startOfPortfolioPage" style={{backgroundColor:"rgba(227, 227, 227, 0.2)",marginTop:"70px"}}>
      <div style={{maxWidth:"1150px",margin:"auto"}}>
        <div style={{padding:"50px 0px 30px 0px",textAlign:"center",fontSize:"40px",color:"rgba(25, 181, 254, 0.7)"}}><u className={styles.underline}>Portfolio</u></div>
        <div style={{padding:"20px 30px 60px 30px",maxWidth:"1000px",margin:"auto"}} >
          <Project />
        </div>
      </div>
    </div>
  );
}


const Project = () => {

  const isLessThan600 = useMediaQuery({ query: '(max-width: 600px)' })

  const [showMore, setShowMore] = useState(false);

  return (
    <div className={styles.p_row}>
      <RectangularIcon 
        image={appleDetectionImage}
        heading="Apple Detection Model"
        text="This is an apple detection model classifying whether an apple is red or green with some given probability"
        text2="Python R Flask"
        link={REACT_APP_APPLE_DETECTION}
      />
      <RectangularIcon 
        image={aflBall}
        heading="Weekly AFL statistics in R"
        text="This shows a continual update of weekly AFL scores."
        link={REACT_APP_AFL}
      />
      <RectangularIcon 
        image={BulldogsScarf}
        heading="Apple Detection Model"
        text="This is an apple detection model classifying whether an apple is red or green with some given probability"  
        link={REACT_APP_NRL}
      />
      {!isLessThan600 &&
        <>
          <RectangularIcon 
            image={timeSeries}
            heading="Apple Detection Model"
            text="This is an apple detection model classifying whether an apple is red or green with some given probability"  
            link={REACT_APP_APPLE_TIMESERIES}
          />
          <RectangularIcon 
            image={PlantAndPlot}
            heading="Apple Detection Model"
            text="This is an apple detection model classifying whether an apple is red or green with some given probability"  
            link={REACT_APP_TELCO_CHURN}
          />
          <div className={styles.p_column}></div>
        </>
      }
      {isLessThan600 && showMore &&
        <>
          <RectangularIcon 
            image={timeSeries}
            heading="Apple Detection Model"
            text="This is an apple detection model classifying whether an apple is red or green with some given probability"  
            link={REACT_APP_APPLE_TIMESERIES}
          />
          <RectangularIcon 
            image={PlantAndPlot}
            heading="Apple Detection Model"
            text="This is an apple detection model classifying whether an apple is red or green with some given probability"  
            link={REACT_APP_TELCO_CHURN}
          />
          {/* <div className={styles.p_column}></div> */}
        </>
      }
      {isLessThan600 && <button className={styles.showButton} onClick={() => setShowMore(!showMore)}>{showMore ? "Show less" : "Show more"}</button>}
    </div>
  );
}


// const Project = () => {

//   const isLessThan600 = useMediaQuery({ query: '(max-width: 600px)' })

//   const [showMore, setShowMore] = useState(false);

//   return (
//     <div className={styles.p_row}>
//       <RectangularIcon 
//         image={appleDetectionImage}
//         heading="Apple Detection Model"
//         text="This is an apple detection model classifying whether an apple is red or green with some given probability"
//         text2="Python R Flask"  
//       />
//       <RectangularIcon 
//         image={aflBall}
//         heading="Weekly AFL statistics in R"
//         text="This shows a continual update of weekly AFL scores."  
//       />
//       <RectangularIcon 
//         image={BulldogsScarf}
//         heading="Apple Detection Model"
//         text="This is an apple detection model classifying whether an apple is red or green with some given probability"  
//       />
//       <RectangularIcon 
//         image={timeSeries}
//         heading="Apple Detection Model"
//         text="This is an apple detection model classifying whether an apple is red or green with some given probability"  
//       />
//       <RectangularIcon 
//         image={PlantAndPlot}
//         heading="Apple Detection Model"
//         text="This is an apple detection model classifying whether an apple is red or green with some given probability"  
//       />
//       <div className={styles.p_column}></div>
//       {isLessThan600 && <button className="btn" style={{textAlign:"center"}} onClick={() => setShowMore(!showMore)}>{showMore ? "Show less" : "Show more"}</button>}
//     </div>
//   );
// }

const RectangularIcon = ({image, heading, text, link}) => {
  return (
    // <div className={styles.p_column} style={{backgroundColor:"blue",width:"350px",height:"300px"}}>
    <div className={styles.p_column} style={{backgroundColor:"rgba(227, 227, 227, 0)",height:"350px"}}>
      {/* <img src={image} href="" style={{width:"300px",height:"150px"}}></img> */}
      <img src={image} href="" style={{height:"200px",width:"100%"}} alt="Image can't be displayed." />
      <div className={styles.p_OSIfontsize}>{heading}</div>
      <div className={styles.p_OSItext}>{text}</div>
      <div className={styles.h_linksPL}><a href={link}>See more</a></div>
    </div>
  );
}

export default Portfolio;





const OneSquareIcon = ({image, heading, text}) => {
  return(
    <div className={styles.a_column}>
      {/* <div className="a_square">{image}</div> */}
      <div className={styles.a_square}><img src={image} href="" style={{width:"80px",height:"100px"}} alt="Image can't be displayed." /></div>
      <div className={styles.a_OSIfontsize}><b style={{color:"rgba(0,0,0,0.7)"}}>{heading}</b></div>
      <div className={styles.a_OSItext}><div style={{maxWidth:450,margin:"auto"}}>{text}</div></div>
    </div>
  );
}
