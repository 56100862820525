import "./Footer.scss";

const Footer = () => {
  return (
    <div style={{backgroundColor:"rgba(0, 0, 0, 1)"}}>
      <div style={{maxWidth:"1150px",margin:"auto"}}>
        <div style={{padding:"30px 0px 0px 0px"}}>
          <FooterLinks />
        </div>
      </div>
    </div>
  );
}


const FooterLinks = () => {

	const mystyles = {
		linkColors: { color:"white" },
		copyRights: { 
			textalign:"center",
			marginTop:40,
      // marginBottom:40,
      paddingBottom: 20, // can't use marginBottom as won't work in Chrome (does in safari though)
			color:"rgba(255, 255, 255, 0.3)",
      textAlign: "center"
		},
		backGround: {
			backgroundColor:"rgba(0,0,0,0.4)",
			height:"100%",
		}
	}

	return (
		// footer
    <div style={mystyles.backGround}>
      <nav className="navbar navbar-expand-custom justify-content-center footer">
        <ul className="navbar-nav marginadj">
          <li className="nav-item">
            <a href="#" className="nav-link" style={mystyles.linkColors}>Home</a>
          </li>
          <li className="nav-item">
            <a href="#startOfAboutPage" className="nav-link" style={mystyles.linkColors}>About</a>
          </li>
          <li className="nav-item">
            <a href="#startOfPortfolioPage" className="nav-link" style={mystyles.linkColors}>Portfolio</a>
          </li>
          <li className="nav-item">
            <a href="#startOfContactPage" className="nav-link" style={mystyles.linkColors}>Contact</a>
          </li>
        </ul>
      </nav>

      <div style={mystyles.copyRights} className="footerMessage">Copyright © 2022 Jake Keenan. All rights reserved</div>

    </div>
	);
}


export default Footer;