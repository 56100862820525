import { findByText } from '@testing-library/react';
import './App.css';
import Home from './Content/Home';
import Navbar from './Content/Navbar';
import About from './Content/About';
import Portfolio from './Content/Portfolio';
import Contact from './Content/Contact';
import Footer from './Content/Footer';
// cool backgrounds: https://www.sliderrevolution.com/resources/css-animated-background/

const App = () => {
  return (
    <div>
      <Navbar />
      <Home />
      <About />
      <Portfolio />
      <Contact />
      <Footer />
    </div>
  );
}

const Template = () => {
  return (
    <div style={{backgroundColor:"black"}}>
      <div style={{maxWidth:"1150px",margin:"auto"}}>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100vh"}}>
          <div style={{flex:"50%",backgroundColor:"blue",textAlign:"left"}}>
            <div>dfssdfds</div>
            <div>dfssdfds</div>
            <div>dfssdfds</div>
            <div>dfssdfds</div>
            <div>dfssdfds</div>
            <div>dfssdfds</div>
          </div>
          <div style={{flex:"50%"}}> hello</div>
        </div>
      </div>
    </div>
  );
}


export default App;