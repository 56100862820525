import styles from './Home.module.scss';
import { motion } from "framer-motion";
const {REACT_APP_GITHUB_PROJECTS, REACT_APP_LINKEDIN, REACT_APP_EMAIL} = process.env


// const Home = () => {
//   return (
//     <div style={{backgroundColor:"black"}}>
//       <div style={{maxWidth:"1075px",margin:"auto"}}>
//         <div className={styles.container}>
//           <div className={styles.leftContainer}>
//             <Title />
//           </div>
//           <div className={styles.rightContainer}>
//             Photo of myself to go here!!!
//             {/* <img src={myselfImage} className={styles.a_imageNumber111}></img> */}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

const Home = () => {
  return (
    <div style={{backgroundColor:"black"}}>
      <div style={{maxWidth:"1075px",margin:"auto"}}>
        <div className={styles.container}>
          <Title />
        </div>
      </div>
    </div>
  );
}

// const Home = () => {
//   return (
//     <div style={{backgroundColor:"black"}}>
//       <div style={{maxWidth:"1075px",margin:"auto"}}>
//         <div className={styles.container}>
//           <Title />
//         </div>
//       </div>
//     </div>
//   );
// }


// Main title
const Title = () => {
  // See: https://brad-carter.medium.com/how-to-animate-a-text-reveal-effect-in-react-with-framer-motion-ae8ddd296f0d

  const line1 = "Hi there, I'm"
  const line2 = "Jake Keenan"
  // const line3 = "Keenan"
  const line4 = "Data Scientist"
  const line5 = "I'm a multi-talented, data minded,"
  const line6 = "individual that enjoys a challenge."

  const sentence = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.5, // Speed at which animation starts when page fully loaded
        staggerChildren: 0.05, // Speed at which each letter gets displayed
      },
    },
  }

  const letter = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <>
      <motion.div
        variants={sentence}
        initial="hidden"
        animate="visible"
      >
        {line1.split("").map((char, index) => {
          return (
            <motion.span key={char + "-" + index} variants={letter} className={styles.line1}>
              {char}
            </motion.span>
          )
        })}
        <br />
        {line2.split("").map((char, index) => {
          return (
            <motion.span key={char + "-" + index} variants={letter} className={styles.lines2and3}>
              {char}
            </motion.span>
          )
        })}
        <br />
        {/* {line3.split("").map((char, index) => {
          return (
            <motion.span key={char + "-" + index} variants={letter} className={styles.lines2and3}>
              {char}
            </motion.span>
          )
        })}
        <br /> */}
        <div style={{marginTop:"10px"}}>
          {line4.split("").map((char, index) => {
            return (
              <motion.span key={char + "-" + index} variants={letter} className={styles.line4}>
                {char}
              </motion.span>
            )
          })}
        </div>
        <div style={{marginTop:"15px"}}>
          {line5.split("").map((char, index) => {
            return (
              <motion.span key={char + "-" + index} variants={letter} className={styles.lines5and6}>
                {char}
              </motion.span>
            )
          })}
          <br />
          {line6.split("").map((char, index) => {
            return (
              <motion.span key={char + "-" + index} variants={letter} className={styles.lines5and6}>
                {char}
              </motion.span>
            )
          })}
        </div>
        <a href={REACT_APP_GITHUB_PROJECTS} className={`${styles.fadeInButton} ${styles.contactMe2}`}>GITHUB</a>
        <a href={REACT_APP_LINKEDIN} style={{margin:"0px 15px 0px 15px"}} className={`${styles.fadeInButton} ${styles.contactMe2}`}>LINKEDIN</a>
        <a href={`mailto: ${REACT_APP_EMAIL}`} className={`${styles.fadeInButton} ${styles.contactMe2}`}>EMAIL</a>
      </motion.div>
    </>
  );

}


// const ThreeBlueButtons = () => {
//   return (
//     <div className={styles.centerThreeBlueButtons}>
//       <div className={styles.threeBlueButtons}>
//         <a href="https://github.com/Keenans213/Data_Science_Projects">GitHub</a>
//         <a href="https://www.linkedin.com/in/keenanj">LinkedIn</a>
//         <a href="mailto: keenanjake7@gmail.com">Email</a>
//       </div>
//     </div>
//   );
// }





export default Home;